/* eslint-disable react/prop-types */
/* eslint-disable no-bitwise */
/* eslint-disable import/no-cycle */
/* eslint-disable no-nested-ternary */
/* eslint-disable array-callback-return */
/* eslint-disable react/no-children-prop */
import React, { useState } from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import { Text, InputGroup, Input, Box, Flex } from '@chakra-ui/react';
import styled from 'styled-components';

export const SpecialsSearchBarStyles = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 414px) {
    display: grid;
    grid-template-columns: 114px 1fr;
    width: calc(100vw - 2rem);
    margin: 0 auto;
  }
  &.comedian {
    grid-template-columns: 128px 1fr;
  }
  .search-input-wrapper {
    position: relative;
    width: 200px;
    @media (max-width: 414px) {
      width: 100%;
    }
  }
  &.search {
    @media (max-width: 640px) {
      display: grid;
      grid-template-columns: 140px 1fr;
      width: calc(100vw - 2rem);
      margin: 0 auto;
      .search-input-wrapper {
        width: 200px;
      }
    }
  }
  .search-input {
    background: #f9f9f9;
    height: 30px;
    padding: 10px 12px;
    font-size: 13px;
    margin-left: 1rem;
    width: 200px;
    @media (max-width: 414px) {
      width: 100%;
    }
  }
  .dropdown {
    border-radius: 5px 5px 0 0;
  }
  .label {
    font-size: 13px;
    text-transform: uppercase;
  }
  .dropdown-wrapper {
    position: absolute;
    top: 30px;
    right: 0;
    width: 190px;
    max-height: 280px;
    overflow-y: scroll;
    background: white;
    z-index: 5;
    background: #4a5568;
    border-radius: 0 0 5px 5px;
    border: 1px solid #e2e8f0;
    border-top: none;
    color: white;
    @media (max-width: 414px) {
      width: calc(100% - 10px);
    }
  }
  #no-label {
    width: 100%;
    .search-input {
      width: 100%;
      margin-left: 0;
    }
    .dropdown-wrapper {
      width: 100%;
    }
  }
  .dropdown-wrapper::-webkit-scrollbar {
    display: none;
  }
  .dropdown-flex {
    padding: 0.3rem 0.5rem 0.3rem 1.2rem;
    border-top: 1px solid #718096;
    &:hover {
      background: #2d3748;
      cursor: pointer;
    }
    p {
      font-size: 12px;
      font-weight: 500;
    }
  }
  #first {
    padding-top: 6px;
    border-top: none;
  }
  .comedian-name {
    font-size: 11px;
    font-weight: 400;
  }
  .create-post-link {
    width: 100%;
  }
  #results {
    @media (max-width: 840px) {
      display: none;
    }
    @media (max-width: 640px) {
      display: inline-block;
    }
  }
  #a-post,
  #a-comedian {
    @media (max-width: 640px) {
      display: none;
    }
    @media (max-width: 414px) {
      display: inline-block;
    }
  }
`;

export const AdviceSearchBar = ({ noLabel }) => {
  const { adviceContents } = useStaticQuery(graphql`
    query {
      adviceContents: allSanityAdviceContent(
        sort: { fields: _createdAt, order: ASC }
      ) {
        nodes {
          id
          title
          slug {
            current
          }
          content
          sourceLink
          sourceTitle
          comedian {
            id
            firstName
            lastName
            slug {
              current
            }
            avatar {
              asset {
                fluid {
                  ...GatsbySanityImageFluid
                }
              }
            }
          }
          categories {
            id
            slug {
              current
            }
            category
          }
          _createdAt
        }
      }
    }
  `);

  const [searchFocused, setSearchFocused] = useState(false);
  const [navigating, setNavigating] = useState(false);
  const [search, setSearch] = useState('');
  const searchTermsArr = search.split(' ').map((term) => term.toLowerCase());
  let adviceFiltered = [...adviceContents.nodes].filter((advice) => {
    let isMatch = false;
    searchTermsArr.forEach((term) => {
      const regEx = new RegExp(term);
      const termMatched =
        regEx.test(advice.title.toLowerCase()) ||
        regEx.test(advice.content.toLowerCase()) ||
        regEx.test(advice.comedian.firstName.toLowerCase()) ||
        regEx.test(advice.comedian.lastName.toLowerCase());
      if (termMatched) {
        isMatch = true;
      }
    });
    if (isMatch) {
      return advice;
    }
  });

  adviceFiltered = adviceFiltered?.sort((a, b) =>
    a.title < b.title ? -1 : a.title > b.title ? 1 : 0
  );
  return (
    <SpecialsSearchBarStyles>
      {!noLabel && (
        <Text className="label">
          Find <span id="a-post"> a post</span>
        </Text>
      )}
      <InputGroup
        className="search-input-wrapper"
        id={noLabel ? 'no-label' : ''}
      >
        <Input
          focusBorderColor="none"
          className={
            searchFocused && search ? 'search-input dropdown' : 'search-input'
          }
          placeholder={noLabel ? 'Find a post' : ''}
          autoComplete="off"
          type="text"
          id="search"
          name="search"
          value={search}
          onFocus={() => setSearchFocused(true)}
          onBlur={() => {
            setTimeout(() => {
              setSearchFocused(false);
            }, 500);
          }}
          onChange={(e) => setSearch(e.target.value)}
        />
        {(searchFocused || navigating) && search && adviceFiltered && (
          <Box className="dropdown-wrapper" onClick={() => setNavigating(true)}>
            {adviceFiltered.map((dropdownAdvice, index) => {
              const adviceTitle =
                dropdownAdvice.title.length > 50
                  ? `${dropdownAdvice.title.slice(0, 50)}...`
                  : dropdownAdvice.title;
              return (
                <React.Fragment key={`${dropdownAdvice.id}|dropdown`}>
                  <Link to={`/post/${dropdownAdvice.slug.current}`}>
                    <Flex
                      className="dropdown-flex"
                      id={index === 0 ? 'first' : ''}
                    >
                      <Text>{adviceTitle}</Text>
                    </Flex>
                  </Link>
                </React.Fragment>
              );
            })}
            {adviceFiltered.length === 0 && (
              <Flex className="dropdown-flex" id="first">
                <Text>No posts matched '{search}'.</Text>
              </Flex>
            )}
          </Box>
        )}
      </InputGroup>
    </SpecialsSearchBarStyles>
  );
};
