/* eslint-disable import/no-cycle */
/* eslint-disable prettier/prettier */
/* eslint-disable react/prop-types */
import React from 'react';
import styled from 'styled-components';
import { ComediansGridStyles } from './ComediansGrid';
import { ComedianTrendingImage } from './ComedianTrendingImage';

const ComediansTrendingStyles = styled.div`
    max-height: 197px;
    overflow: hidden;
    @media (max-width: 1040px) {
      max-height: 190.5px;
    }
    @media (max-width: 840px) {
      max-height: 197px;
    }
    @media (max-width: 640px) {
      max-height: 189.5px;
    }
    @media (max-width: 414px) {
        max-height: 286px;
    }
`;

export const ComediansTrending = ({ comedians }) => (
    <ComediansTrendingStyles>
        <ComediansGridStyles id="trending">
            {comedians.map((comedian, index) => (
                <ComedianTrendingImage comedian={comedian} key={comedian.id} index={index} />
            ))}
        </ComediansGridStyles>
    </ComediansTrendingStyles>
  );
