/* eslint-disable react/prop-types */
/* eslint-disable no-bitwise */
/* eslint-disable import/no-cycle */
/* eslint-disable no-nested-ternary */
/* eslint-disable array-callback-return */
/* eslint-disable react/no-children-prop */
import React, { useState } from 'react';
import { Text, InputGroup, Input, Box, Flex } from '@chakra-ui/react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import { SpecialsSearchBarStyles } from './AdviceSearchBar';

export const ComediansSearchBar = ({ noLabel }) => {
  const { comedians } = useStaticQuery(graphql`
    query {
      comedians: allSanityComedian(sort: { fields: lastName, order: ASC }) {
        nodes {
          id
          firstName
          lastName
          slug {
            current
          }
          avatar {
            asset {
              fluid {
                ...GatsbySanityImageFluid
              }
            }
          }
          # genres {
          #   id
          #   genre
          # }
          # categories {
          #   id
          #   category
          # }
          _createdAt
        }
      }
    }
  `);

  const [searchFocused, setSearchFocused] = useState(false);
  const [navigating, setNavigating] = useState(false);
  const [search, setSearch] = useState('');
  const searchTermsArr = search.split(' ').map((term) => term.toLowerCase());

  let comediansFiltered = [...comedians.nodes].filter((comedian) => {
    let isMatch = false;
    searchTermsArr.forEach((term) => {
      const regEx = new RegExp(term);
      const termMatched =
        regEx.test(comedian.firstName.toLowerCase()) |
        regEx.test(comedian.lastName.toLowerCase());
      if (termMatched) {
        isMatch = true;
      }
    });
    if (isMatch) {
      return comedian;
    }
  });

  comediansFiltered = comediansFiltered.sort((a, b) =>
    a.lastName < b.lastName ? -1 : a.lastName > b.lastName ? 1 : 0
  );
  //   const {length} = specialsFiltered;
  //   const startIndex = page * perPage - perPage;
  //   const endIndex = startIndex + perPage;
  //   specialsFiltered = specialsFiltered.slice(startIndex, endIndex);
  return (
    <SpecialsSearchBarStyles className="comedian">
      {!noLabel && (
        <Text className="label">
          Find <span id="a-comedian"> a comedian</span>
        </Text>
      )}
      <InputGroup
        className="search-input-wrapper"
        id={noLabel ? 'no-label' : ''}
      >
        <Input
          focusBorderColor="none"
          className={
            searchFocused && search ? 'search-input dropdown' : 'search-input'
          }
          autoComplete="off"
          placeholder={noLabel ? 'Find a comedian' : ''}
          type="text"
          id="search"
          name="search"
          value={search}
          onFocus={() => setSearchFocused(true)}
          onBlur={() => {
            setTimeout(() => {
              setSearchFocused(false);
            }, 500);
          }}
          onChange={(e) => setSearch(e.target.value)}
        />
        {(searchFocused || navigating) && search && comediansFiltered && (
          <Box className="dropdown-wrapper" onClick={() => setNavigating(true)}>
            {comediansFiltered.map((dropdownComedian, index) => (
              <React.Fragment key={`${dropdownComedian.id}|dropdown`}>
                <Link to={`/comedian/${dropdownComedian.slug.current}`}>
                  <Flex
                    className="dropdown-flex"
                    id={index === 0 ? 'first' : ''}
                  >
                    <Text>
                      {dropdownComedian.firstName} {dropdownComedian.lastName}
                    </Text>
                  </Flex>
                </Link>
              </React.Fragment>
            ))}
            {comediansFiltered.length === 0 && (
              <Flex className="dropdown-flex" id="first">
                <Text>No comedians matched '{search}'.</Text>
              </Flex>
            )}
          </Box>
        )}
      </InputGroup>
    </SpecialsSearchBarStyles>
  );
};
