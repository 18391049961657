/* eslint-disable no-nested-ternary */
/* eslint-disable no-plusplus */
/* eslint-disable import/no-cycle */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Box } from '@chakra-ui/react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import { ComedianImageHoveredPopup } from './ComedianImageHoveredPopup';

export const GridImagesSmallStyles = styled.div`
  position: relative;
  .image-wrapper {
    width: 95.5px;
    height: 95.5px;
    border-radius: 5px;
    overflow: hidden;
    @media (max-width: 1040px) {
      width: 92.5px;
      height: 92.5px;
    }
    @media (max-width: 840px) {
      width: 95.7px;
      height: 95.7px;
    }
    @media (max-width: 640px) {
      width: 100%;
      height: 92px;
    }
  }
  .image {
    width: 95.5px;
    height: 95.5px;
    object-fit: cover;
    border-radius: 5px;
    filter: none;
    border: 1px solid #b8c9da;
    transition: all 0.3s;
    &:hover {
      border: 1px solid var(--facebook);
      cursor: pointer;
    }
    @media (max-width: 1040px) {
      width: 92.5px;
      height: 92.5px;
    }
    @media (max-width: 840px) {
      width: 95.7px;
      height: 95.7px;
    }
    @media (max-width: 640px) {
      width: 100%;
      height: 100%;
    }
  }
  .stars-flex {
    margin-top: 0.3rem;
    padding: 0 0.3rem;
    font-size: 15px;
    font-weight: 300;
    color: var(--facebook);
  }
  .name-popup-grid {
    padding: 0.4rem 0.2rem;
    grid-template-columns: 1fr;
    justify-items: center;
    position: absolute;
    bottom: 3px;
    left: 3px;
    width: calc(100% - 6px);
    z-index: 2;
    background: black;
    border-radius: 3px;
    p {
      text-align: center;
      font-size: 10px;
      color: white;
    }
  }
`;

export const ComedianTrendingImage = ({ comedian, index, standalonePage }) => {
  const [hovered, setHovered] = useState(false);
  return (
    <GridImagesSmallStyles
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      className={
        standalonePage
          ? ''
          : index > 15
          ? 'sixteen'
          : index > 11
          ? 'twelve'
          : ''
      }
    >
      {hovered && <ComedianImageHoveredPopup comedian={comedian} />}
      <Link to={`/comedian/${comedian.slug.current}`}>
        <Box className="image-wrapper" key={comedian.id}>
          <Img
            className="image"
            fluid={comedian.avatar.asset.fluid}
            alt={`${comedian.firstName} ${comedian.lastName}`}
          />
        </Box>
      </Link>
    </GridImagesSmallStyles>
  );
};
