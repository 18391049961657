/* eslint-disable import/no-cycle */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-children-prop */
import React from 'react';
import { Text, Select, Box } from '@chakra-ui/react';
import { graphql, useStaticQuery } from 'gatsby';
import { useNavigate } from '@reach/router';
import { SpecialsHeaderStyles } from './AdviceSortByHeader';
import { ComediansSearchBar } from './ComediansSearchBar';

export const ComediansSortByHeader = ({ noUnderline }) => {
  const navigate = useNavigate();

  const { categories } = useStaticQuery(graphql`
    query {
      categories: allSanityComedianCategory(
        sort: { fields: category, order: ASC }
      ) {
        nodes {
          id
          category
          slug {
            current
          }
          comedians {
            id
            firstName
            lastName
          }
        }
      }
    }
  `);

  const handleCategoryChange = (slug) => {
    navigate(`/comedians/category/${slug}`);
  };
  return (
    <>
      <SpecialsHeaderStyles
        id={noUnderline ? 'no-underline' : ''}
        className="comedians"
      >
        <Text className="label">Browse by</Text>
        <Box id="category">
          <Select
            className="browse-by-select"
            size="lg"
            onChange={(e) => handleCategoryChange(e.target.value)}
          >
            <option value="">CATEGORY</option>
            {categories.nodes.map((category) => (
              <option key={category.id} value={category.slug.current}>
                {category.category}
              </option>
            ))}
          </Select>
        </Box>
        {/* <Select
          className="browse-by-select"
          size="lg"
          onChange={(e) => handleChange(e.target.value, 'popular')}
        >
          <option value="">POPULAR</option>
          <option value="all-time">All time</option>
          <option value="this-year">This year</option>
          <option value="this-month">This month</option>
          <option value="this-week">This week</option>
          <option value="today">Today</option>
        </Select> */}
        <Box className="search-wrapper">
          <ComediansSearchBar />
        </Box>
      </SpecialsHeaderStyles>
    </>
  );
};
