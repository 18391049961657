/* eslint-disable react/no-children-prop */
/* eslint-disable import/no-cycle */
/* eslint-disable no-nested-ternary */
/* eslint-disable arrow-body-style */
/* eslint-disable react/prop-types */
/* eslint-disable prettier/prettier */
import { Box, Text } from '@chakra-ui/react';
import React from 'react';
import styled from 'styled-components';
import { generateId } from '../utils/generateId';
import { AdviceBrowseBy } from './AdviceBrowseBy';
import { AdviceContentSingle } from './AdviceContentSingle';
import { AdviceSortByHeader } from './AdviceSortByHeader';
import { NumberContentFoundStyles } from './ComediansGrid';
import { GenericHeader } from './GenericHeader';
// import { AdviceSortByHeader } from './AdviceSortByHeader';

export const AdviceGridStyles = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 1rem;
`;

export const AbsoluteHeaderStyles = styled.div`
  position: relative;
  margin-bottom: 70px;
  height: 0.1px;
  @media (max-width: 840px) {
    margin-bottom: 100px;
  }
  /* @media (max-width: 640px) {
    display: none;
  } */
  &#search {
    @media (max-width: 640px) {
      display: block;
    }
  }
`;

export const AdviceBrowseByStyles = styled.div`
  display: none;
  @media (max-width: 640px) {
    display: block;
    position: absolute;
    right: 0;
    top: -8.5px;
  }
  /* @media (max-width: 414px) {
    bottom: 3px;
  } */
`;

// TODO: adviceSortByHeader

export const AdviceGrid = ({ allAdvice, standalonePage, type, totalLength, singleAdvicePage, comedian }) => {
  let isOnlyOne;
  let postOrPosts;
  let typeFormatted;
  if (type || comedian) {
    isOnlyOne = totalLength === 1;
    postOrPosts = isOnlyOne ? 'post' : 'posts';
    if (type) {
      typeFormatted = type !== 'post' ? `${postOrPosts} tagged '${type.slice(0,1).toUpperCase()}${type.slice(1)}'` : `${postOrPosts}`;
    }
    if (comedian) {
      typeFormatted = `${postOrPosts} from the mind of ${comedian}`;
    }
  }
  return (
    <>
      {/* {standalonePage && (
        <Box position="relative">
          <AbsoluteHeaderStyles>
            <AdviceSortByHeader noUnderline />
          </AbsoluteHeaderStyles>
          <AdviceBrowseByStyles>
            <AdviceBrowseBy />
          </AdviceBrowseByStyles>
          <GenericHeader header="Posts" />
          {type && (
            <NumberContentFoundStyles>There {isOnlyOne ? 'is' : 'are'} {totalLength} {typeFormatted}.</NumberContentFoundStyles>
          )}
        </Box>
      )}
      {!standalonePage && !comedian && (
        <GenericHeader header="Posts" href="/posts" />
      )}
      {!standalonePage && comedian && (
        <>
          <GenericHeader header="Posts" />
          {comedian && (
            <Text fontSize="13px" fontWeight="500" color="#4056a1" margin="10px 0" textAlign="center">There {isOnlyOne ? 'is' : 'are'} {totalLength} {typeFormatted}.</Text>
          )}
        </>
      )} */}
        <AdviceGridStyles>
            {allAdvice.map((advice, index) => {
                return (
                    <AdviceContentSingle key={generateId()} advice={advice} index={index} singleAdvicePage={singleAdvicePage} />
                )
            })}
        </AdviceGridStyles>
    </>
  )
};
