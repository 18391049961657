/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable array-callback-return */
/* eslint-disable no-param-reassign */
/* eslint-disable no-nested-ternary */
import styled from 'styled-components';
import React from 'react';
import { Box, Text, Flex, Grid, Button } from '@chakra-ui/react';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import { FaBirthdayCake, FaExternalLinkSquareAlt } from 'react-icons/fa';
import { MdLocationOn } from 'react-icons/md';
import { GiTombstone } from 'react-icons/gi';
import { SiApplepodcasts } from 'react-icons/si';
import { GoPlus } from 'react-icons/go';
import {
  AiFillFacebook,
  AiFillHeart,
  AiFillInstagram,
  AiFillTwitterSquare,
  AiFillYoutube,
  AiOutlineFieldTime,
  AiOutlineHeart,
} from 'react-icons/ai';
import { getMonthStr } from '../utils/getMonthStr';
import { getAge } from '../utils/getAge';
import { getAgeAtDeath } from '../utils/getAgeAtDeath';
import { slugify } from '../utils/slugify';
import { useIncreaseComedianViews } from '../utils/useIncreaseComedianViews';

const ComedianDetailsStyles = styled.div`
  display: grid;
  grid-template-columns: auto 1fr 1fr;
  grid-gap: 3rem;
  align-items: center;
  padding-bottom: 1rem;
  width: 1000px;
  margin: 0 auto;
  overflow: hidden;
  @media (max-width: 1040px) {
    width: 800px;
    grid-template-columns: auto 1fr;
  }
  @media (max-width: 840px) {
    width: 600px;
    grid-template-columns: 1fr;
    justify-items: center;
    grid-gap: 0;
  }
  @media (max-width: 640px) {
    width: 400px;
  }
  @media (max-width: 414px) {
    width: 333.334px;
    margin-top: 1rem;
  }
  .avatar-wrapper {
    position: relative;
    width: 300px;
    height: 300px;
  }
  .avatar {
    display: block;
    width: 300px;
    height: 300px;
    object-fit: cover;
  }
  .add-btn {
    font-size: 10px;
    min-height: 20px;
    height: 20px;
    min-width: 20px;
    width: 20px;
    padding: 0;
    border: 1px solid #cbd5e0;
    margin-left: 10px;
    @media (max-width: 840px) {
      margin-bottom: -8px;
    }
  }
  .socials-flex {
    position: absolute;
    left: 5px;
    bottom: 5px;
    background: rgba(0, 0, 0, 0.8);
    padding: 0.3rem;
  }
  .social-btn {
    padding: 2rem 1rem;
  }
  .name-socials-wrapper {
    align-self: start;
    margin-top: 1rem;
    grid-template-rows: 47px auto 1fr auto;
    height: 280px;
    @media (max-width: 840px) {
      height: auto;
      grid-template-rows: 47px auto 1fr auto;
      justify-items: center;
      margin-top: 0;
    }
  }
  .name {
    font-size: 32px;
    font-weight: 400;
    @media (max-width: 840px) {
      margin-top: 1rem;
    }
  }
  .genres-flex {
    flex-wrap: wrap;
    align-items: center;
    grid-gap: 3px;
    /* margin-top: 5px; */
    line-height: 0.5;
    @media (max-width: 840px) {
      justify-items: center;
      justify-content: center;
      padding: 0 8rem;
      margin-top: 0.7rem;
    }
    @media (max-width: 640px) {
      padding: 0 2rem;
    }
  }
  .genre {
    font-size: 12px;
    font-weight: 500;
    color: #4056a1;
    text-transform: uppercase;
    letter-spacing: -0.3px;
    line-height: 0.3;
    @media (max-width: 840px) {
      text-align: center;
    }
  }
  .featured-joke-wrapper {
    margin-top: 2rem;
    @media (max-width: 1040px) {
      padding-right: 3rem;
    }
    @media (max-width: 840px) {
      margin-top: 3rem;
      padding: 0 8rem;
      text-align: center;
    }
    @media (max-width: 640px) {
      padding: 0 2rem;
      margin-top: 2rem;
    }
  }
  .featured-joke {
    line-height: 1.5;
    font-size: 14px;
    letter-spacing: 0;
  }
  .personal-details-outer-wrapper {
    @media (max-width: 1040px) {
      grid-column: 1 / -1;
    }
    @media (max-width: 840px) {
      margin-top: 2rem;
    }
    @media (max-width: 640px) {
      margin-top: 4rem;
    }
  }
  .personal-details-inner-wrapper {
    background: #f1f1f1;
    @media (max-width: 1040px) {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      justify-items: center;
    }
    @media (max-width: 840px) {
      grid-template-columns: 1fr 1fr;
    }
    @media (max-width: 414px) {
      display: block;
      min-width: 200px;
    }
    .header {
      font-size: 10px;
      font-weight: 500;
      text-transform: uppercase;
      color: gray;
    }
    .content {
      margin-top: -0.4rem;
      letter-spacing: 0;
    }
  }
  #birthday-wrapper {
    @media (max-width: 840px) {
      grid-row: 2 / span 1;
      grid-column: 1 / -1;
      justify-self: center;
      border: none;
    }
    @media (max-width: 414px) {
      border-bottom: 1px solid #d8d8d8;
    }
  }
  .content-wrapper {
    padding: 1rem 1.5rem;
    border-bottom: 1px solid #d8d8d8;
    display: grid;
    grid-template-columns: 40px 1fr;
    align-items: center;
    #wide {
      @media (max-width: 840px) {
        display: none;
      }
    }
    #narrow {
      display: none;
      @media (max-width: 840px) {
        display: block;
      }
    }
    @media (max-width: 1040px) {
      border: none;
    }
    @media (max-width: 840px) {
      border-bottom: 1px solid #d8d8d8;
    }
  }
  .last {
    border: none;
    @media (max-width: 840px) {
      width: 100%;
      border-bottom: 1px solid #d8d8d8;
    }
    @media (max-width: 414px) {
      border: none;
    }
  }
  #death {
    @media (max-width: 1040px) {
      grid-template-columns: 1fr 1fr auto;
      justify-items: center;
    }
    @media (max-width: 840px) {
      justify-items: center;
    }
    #birthday-wrapper {
      @media (max-width: 840px) {
        grid-row: 1 / span 1;
        grid-column: 1 / span 1;
        justify-self: start;
        border-bottom: 1px solid #d8d8d8;
      }
    }
    #birthplace-wrapper {
      @media (max-width: 840px) {
        width: 100%;
      }
    }
    .last {
      @media (max-width: 840px) {
        grid-row: 2 / span 1;
        grid-column: 1 / -1;
        justify-self: center;
        border: none;
        width: auto;
      }
    }
  }
  .popularity-ranking-outer-wrapper {
    @media (max-width: 840px) {
      margin-top: 2rem;
    }
    .popularity-ranking-inner-wrapper {
      background: #4056a1;
      border-radius: 3px;
      padding: 0.5rem 0;
      color: white;
      width: 90px;
      height: 45px;
      display: grid;
      grid-template-columns: 1fr;
      &:hover {
        cursor: pointer;
        background: #29487d;
      }
      .rank {
        font-size: 14px;
        text-align: center;
      }
      .sub-header {
        font-size: 10px;
        font-weight: 500;
        text-align: center;
        letter-spacing: 0;
      }
    }
  }
  .popularity-btns-desktop {
    @media (max-width: 640px) {
      display: none;
    }
  }
  .popularity-btns-mobile {
    display: none;
    @media (max-width: 640px) {
      display: flex;
    }
    @media (max-width: 414px) {
      margin-bottom: 1rem;
    }
  }
`;

// TODO: trending and popular rankings

export const ComedianDetails = ({ comedian }) => {
  const { fetchRes } = useIncreaseComedianViews(comedian);
  const month = getMonthStr(comedian.birthMonth || 1);
  const birthdayStr = `${month} ${comedian.birthDay || 1}, ${
    comedian.birthYear || 1970
  }`;
  const comedianAge = getAge(birthdayStr);

  let deathMonth;
  let deathStr;
  let ageAtDeath;
  if (comedian.deathMonth) {
    deathMonth = getMonthStr(comedian.deathMonth);
    deathStr = `${deathMonth} ${comedian.deathDay}, ${comedian.deathYear}`;
    ageAtDeath = getAgeAtDeath(birthdayStr, deathStr);
  }

  let birthPlace = `${comedian.birthCity}, ${comedian.birthState}`;
  if (!comedian.birthState || comedian.birthState === 'District of Columbia') {
    birthPlace = `${comedian.birthCity}`;
  }
  let birthPlaceAbbreviated = `${comedian.birthCity}, ${comedian.birthStateAbbreviation}`;
  if (comedian.birthState === 'District of Columbia') {
    birthPlaceAbbreviated = `Washington, DC`;
  }

  //   let numberSocialsLinks = 0;
  //   if (comedian.twitter) {
  //     numberSocialsLinks += 1;
  //   }
  //   if (comedian.facebook) {
  //     numberSocialsLinks += 1;
  //   }
  //   if (comedian.instagram) {
  //     numberSocialsLinks += 1;
  //   }
  //   if (comedian.website) {
  //     numberSocialsLinks += 1;
  //   }
  //   if (comedian.podcast) {
  //     numberSocialsLinks += 1;
  //   }
  //   if (comedian.youtube) {
  //     numberSocialsLinks += 1;
  //   }

  const { categories: categorys } = comedian;
  const categorysLength = categorys.length;
  const categorysStr = categorys.reduce(
    (acc, category, index) =>
      (acc +=
        index > 0 && index < categorysLength - 1
          ? `${category.category.toLowerCase()} • `
          : index > 0 && index === categorysLength - 1
          ? `${category.category.toLowerCase()}`
          : `${category.category} • `),
    ''
  );

  const featuredLine =
    comedian.featuredLine ||
    `There's a reason it's called 'girls gone wild' and not 'women gone wild'. When girls go wild, they show their tits. When women go wild, they kill men and drown their kids in a tub.`;
  const isLongName = comedian.firstName.length + comedian.lastName.length >= 18;
  return (
    <ComedianDetailsStyles>
      <Box className="avatar-wrapper">
        <Img
          className="avatar"
          fluid={comedian.avatar.asset.fluid}
          alt={`${comedian.firstName} ${comedian.lastName}`}
        />
        {/* <Flex className="socials-flex">
                {comedian.twitter && (
                    <Box mt="0.5px" className="social-item">
                        <a href={`https://www.twitter.com/${comedian.twitter}`} target="blank">
                            <AiFillTwitterSquare fontSize="20px" color="#CBD5E0" /> 
                        </a>
                    </Box>
                )}
                {comedian.facebook && (
                    <Box className="social-item">
                        <a href={comedian.facebook} target="blank">
                            <AiFillFacebook fontSize="21px" color="#CBD5E0" />
                        </a>
                    </Box>
                )}
                {comedian.instagram && (
                    <Box mt="0.5px" className="social-item">
                        <a href={`https://www.instagram.com/${comedian.instagram}`} target="blank">
                            <AiFillInstagram fontSize="20.75px" color="#CBD5E0" />
                        </a>
                    </Box>
                )}
                {comedian.podcast && (
                    <Box className="social-item" mt="2.5px" ml={numberSocialsLinks === 1 ? 0 : "2px"}>
                        <a href={comedian.podcast} target="blank">
                            <SiApplepodcasts fontSize="16px" color="#CBD5E0" />
                        </a>
                    </Box>
                )}
                {comedian.website && (
                    <Box className="social-item" mt="2.5px" ml={numberSocialsLinks === 1 ? 0 : comedian.podcast ? '4px' : '1.75px'}>
                        <a href={comedian.website} target="blank">
                            <FaExternalLinkSquareAlt fontSize="17px" color="#CBD5E0" />
                        </a>
                    </Box>
                )}
                {comedian.youtube && (
                    <Box className="social-item" mb="-0.5px" ml={numberSocialsLinks === 1 ? 0 : "2.5px"}>
                        <a href={comedian.youtube} target="blank">
                            <AiFillYoutube fontSize="22px" color="#CBD5E0" />
                        </a>
                    </Box>
                )}
            </Flex> */}
      </Box>
      <Grid className="name-socials-wrapper">
        <Flex alignItems="center" flexWrap="wrap">
          <h1
            className="name"
            style={{ fontSize: isLongName ? '28px' : '32px' }}
          >
            {comedian.firstName} {comedian.lastName}
          </h1>
          <Link
            to={`/create-post?c=${slugify(
              `${comedian.firstName} ${comedian.lastName}`
            )}`}
          >
            <Button className="add-btn">
              <GoPlus />
            </Button>
          </Link>
        </Flex>
        <Flex className="genres-flex">
          {comedian.primaryCategories.map((cat, index) => (
            <React.Fragment key={cat.id}>
              <Link to={`/comedians/category/${slugify(cat.category)}`}>
                <Text
                  lineHeight="1"
                  className="genre"
                  mt={comedian.primaryCategories.length === 1 ? '5px' : '0'}
                >
                  {cat.category}
                </Text>
              </Link>
              {index + 1 <
                (comedian.primaryCategories.length >= 3
                  ? 3
                  : comedian.primaryCategories.length) && (
                <Text lineHeight="1" color="#4056a1" mb="2px">
                  &bull;
                </Text>
              )}
            </React.Fragment>
          ))}
        </Flex>
        <Box className="featured-joke-wrapper">
          <Text className="featured-joke">"{featuredLine}"</Text>
        </Box>
        <Flex className="popularity-btns-desktop">
          <>
            <Link to="/comedians">
              <Box className="popularity-ranking-outer-wrapper">
                <Box className="popularity-ranking-inner-wrapper">
                  <Text className="rank">#{comedian.rankAllTime}</Text>
                  <Text className="sub-header">most popular</Text>
                </Box>
              </Box>
            </Link>
            <Link to="/comedians">
              <Box ml={2} className="popularity-ranking-outer-wrapper">
                <Box className="popularity-ranking-inner-wrapper">
                  <Text className="rank">#{comedian.rankThisWeek}</Text>
                  <Text className="sub-header">trending</Text>
                </Box>
              </Box>
            </Link>
          </>
        </Flex>
      </Grid>
      <Box className="personal-details-outer-wrapper">
        <Box
          className="personal-details-inner-wrapper"
          id={comedian.deathMonth ? 'death' : ''}
        >
          <Box className="content-wrapper" id="birthday-wrapper">
            <FaBirthdayCake fontSize="17px" color="#4056a1" />
            <Box>
              <Text className="header">Birthday</Text>
              <Text className="content">{birthdayStr}</Text>
            </Box>
          </Box>
          <Box className="content-wrapper" id="birthplace-wrapper">
            <MdLocationOn fontSize="20px" color="#4056a1" />
            <Box>
              <Text className="header">Birthplace</Text>
              <Text className="content" id="wide">
                {birthPlace}
              </Text>
              <Text className="content" id="narrow">
                {birthPlaceAbbreviated}
              </Text>
            </Box>
          </Box>
          <Box className="content-wrapper last">
            {!comedian.deathMonth && (
              <AiOutlineFieldTime fontSize="20px" color="#4056a1" />
            )}
            {comedian.deathMonth && (
              <GiTombstone fontSize="20px" color="#4056a1" />
            )}
            <Box>
              <Text className="header">
                {comedian.deathDay ? 'Death date' : 'Age'}
              </Text>
              {!comedian.deathMonth && (
                <Text className="content">{comedianAge} years old</Text>
              )}
              {comedian.deathMonth && (
                <Text className="content">
                  {deathStr} ({ageAtDeath} years old)
                </Text>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
      <Flex className="popularity-btns-mobile">
        <>
          <Link to="/comedians">
            <Box className="popularity-ranking-outer-wrapper">
              <Box className="popularity-ranking-inner-wrapper">
                <Text className="rank">#{comedian.rankAllTime}</Text>
                <Text className="sub-header">most popular</Text>
              </Box>
            </Box>
          </Link>
          <Link to="/comedians">
            <Box ml={2} className="popularity-ranking-outer-wrapper">
              <Box className="popularity-ranking-inner-wrapper">
                <Text className="rank">#{comedian.rankThisWeek}</Text>
                <Text className="sub-header">trending</Text>
              </Box>
            </Box>
          </Link>
        </>
      </Flex>
    </ComedianDetailsStyles>
  );
};
