/* eslint-disable react/no-children-prop */
/* eslint-disable import/no-cycle */
/* eslint-disable no-nested-ternary */
/* eslint-disable arrow-body-style */
/* eslint-disable react/prop-types */
/* eslint-disable prettier/prettier */
import React from 'react';
import styled from 'styled-components'
import { Box } from '@chakra-ui/react';
import { GenericHeader } from './GenericHeader';
import { AbsoluteHeaderStyles, AdviceBrowseByStyles } from './AdviceGrid';
import { ComedianImageSmall } from './ComedianImageSmall';
import { ComediansSortByHeader } from './ComediansSortByHeader';
import { ComediansBrowseBy } from './ComediansBrowseBy';
import { DesktopWrapper } from '../templates/Post';

// TODO: type of comedian => {getTypeFormatted(type)}

export const ComediansGridStyles = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, 95.5px);
    grid-gap: 5px;
    @media (max-width: 840px) {
        grid-template-columns: repeat(auto-fit, 95.7px);
    }
    @media (max-width: 640px) {
        grid-template-columns: repeat(auto-fill, minmax(90px, 1fr));
    }
    &#trending {
      @media (max-width: 1040px) {
        grid-template-columns: repeat(auto-fit, 92.5px);
      }
      @media (max-width: 840px) {
        grid-template-columns: repeat(auto-fit, 95.7px);
      }
      @media (max-width: 640px) {
          grid-template-columns: repeat(auto-fill, minmax(90px, 1fr));
      }
    }
`;

const ComedianPageStyles = styled.div`
  width: 1000px;
  margin: 0 auto;
  @media (max-width: 1040px) {
    width: 800px;
  }
  @media (max-width: 840px) {
    width: 600px;
  }
  @media (max-width: 640px) {
    width: 95vw;
  }
`;

export const NumberContentFoundStyles = styled.div`
  font-size: 13px;
  font-weight: 500;
  color: #4056a1;
  margin: 10px 0;
  text-align: center;
`;

export const ComediansGrid = ({ comedians, standalonePage, type, category }) => {
  let typeFormatted = type ? `${type.toLowerCase()}` : 'comedian';
  if (comedians.length !== 1) {
    typeFormatted = `${typeFormatted}s`;
  }
  return (
    <ComedianPageStyles>
      {/* {standalonePage && (
        <Box position="relative">
          <DesktopWrapper>
            <AbsoluteHeaderStyles>
              <ComediansSortByHeader noUnderline />
            </AbsoluteHeaderStyles>
            <AdviceBrowseByStyles>
              <ComediansBrowseBy />
            </AdviceBrowseByStyles>
          </DesktopWrapper>
          <GenericHeader header="Comedians" />
          {(type || category) && (
            <NumberContentFoundStyles>There are {comedians.length} {category || typeFormatted}.</NumberContentFoundStyles>
          )}
        </Box>
      )}
      {!standalonePage && (
        <GenericHeader header="Comedians" href="/comedians" />
      )} */}
      <ComediansGridStyles>
          {comedians.map((comedian, index) => (
              <ComedianImageSmall comedian={comedian} key={comedian.id} index={index} standalonePage={standalonePage} />
          ))}
      </ComediansGridStyles>
    </ComedianPageStyles>
  )
};
