import { useEffect, useState } from 'react';

export const useIncreaseComedianViews = (comedian) => {
  const [fetchRes, setFetchRes] = useState([]);
  const baseUrl =
    process.env.NODE_ENV === 'development'
      ? process.env.GATSBY_DEV_ENDPOINT
      : process.env.GATSBY_PROD_ENDPOINT;
  useEffect(() => {
    const increaseComedianViews = async () => {
      const fetchUrl = `${baseUrl}/api/v1/comedian/${comedian?.id || ''}`;
      const res = await fetch(fetchUrl, {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
        },
      })
        .then(function (response) {
          return response.json();
        })
        .catch(function (error) {
          console.log('Request failed', error);
        });
      setFetchRes(res?.data || []);
    };
    increaseComedianViews();
  }, [comedian]);
  return { fetchRes };
};
