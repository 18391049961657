/* eslint-disable no-nested-ternary */
import React from 'react';
import { Text, Grid, Box } from '@chakra-ui/react';
import styled from 'styled-components';
import { graphql, useStaticQuery, Link } from 'gatsby';
import Img from 'gatsby-image';

const ComediansTrendingListStyles = styled.div`
  background: #f9f9f9;
  border: 1px solid #e2e8f0;
  border-radius: 5px;
  h2 {
    padding: 10px;
    border-bottom: 1px solid #e2e8f0;
    font-weight: 700;
  }
  .comedian-grid {
    padding: 4px 10px;
    grid-template-columns: auto 1fr;
    grid-gap: 1rem;
    align-items: center;
    &#first {
      padding-top: 6px;
    }
    &#last {
      padding-bottom: 6px;
    }
    &:hover {
      background: #e2e8f0;
    }
  }
  p {
    font-size: 12px;
    display: block;
    letter-spacing: 0;
  }
  .comedian-avatar {
    width: 30px;
    height: 30px;
    object-fit: cover;
    border-radius: 50%;
  }
  .show-button {
    padding: 6px 10px 3px 10px;
    border-top: 1px solid #e2e8f0;
    font-weight: 600;
    &:hover {
      cursor: pointer;
      background: #e2e8f0;
    }
  }
`;

export const ComediansTrendingList = () => {
  const { comedians } = useStaticQuery(graphql`
    query {
      comedians: allSanityComedian(
        sort: { fields: viewsThisWeek, order: DESC }
        limit: 20
      ) {
        nodes {
          id
          firstName
          lastName
          slug {
            current
          }
          avatar {
            asset {
              fluid {
                ...GatsbySanityImageFluid
              }
            }
          }
          viewsThisWeek
        }
      }
    }
  `);
  const comediansTrendingThisWeek = [...comedians.nodes].sort((a, b) =>
    a.viewsThisWeek > b.viewsThisWeek
      ? -1
      : a.viewsThisWeek < b.viewsThisWeek
      ? 1
      : 0
  );

  return (
    <ComediansTrendingListStyles>
      <h2>Popular comedians</h2>
      {comediansTrendingThisWeek.map((comedian, index) => (
        <Link to={`/comedian/${comedian.slug.current}`}>
          <Grid
            className="comedian-grid"
            id={index === 0 ? 'first' : index + 1 === 14 ? 'last' : ''}
          >
            <Box className="avatar-wrapper">
              <Img
                className="comedian-avatar"
                fluid={comedian.avatar.asset.fluid}
                alt={`${comedian.firstName} ${comedian.lastName}`}
              />
            </Box>
            <Text>
              {comedian.firstName} {comedian.lastName}
            </Text>
          </Grid>
        </Link>
      ))}
      <Link to="/comedians">
        <Text className="show-button">More</Text>
      </Link>
    </ComediansTrendingListStyles>
  );
};
