/* eslint-disable no-nested-ternary */
/* eslint-disable array-callback-return */
/* eslint-disable react/prop-types */
/* eslint-disable import/no-cycle */
/* eslint-disable no-return-assign */
/* eslint-disable no-param-reassign */
/* eslint-disable no-else-return */
/* eslint-disable prettier/prettier */

import React from 'react';
import { graphql, Link } from 'gatsby';
import { Box, Text } from '@chakra-ui/react';
import styled from 'styled-components';
import SEO from '../components/SEO';
import { AdviceGridStyles } from '../components/AdviceGrid';
import { PageStyles, RightPanelStyles, StandardGridStyles } from '../pages';
import { shuffleArray } from '../utils/shuffleArray';
import { AdviceContentSingle } from '../components/AdviceContentSingle';
import { generateId } from '../utils/generateId';
import { slugify } from '../utils/slugify';
import { GenericHeader, GenericHeaderStyles } from '../components/GenericHeader';
import { AdviceCategories } from '../components/AdviceCategories';
import { CreatePostInputLink } from '../components/CreatePostInputLink';
import { ComediansTrendingList } from '../components/ComediansTrendingList';
import { ComediansSearchBar } from '../components/ComediansSearchBar';

export const DesktopWrapper = styled.div`
  @media (max-width: 414px) {
    display: none;
  }
`;

export const MobileWrapper = styled.div`
  display: none;
  @media (max-width: 414px) {
    display: block;
  }
`;

const RelatedPostsStyles = styled.div`
  margin-top: 35px;
  @media (max-width: 414px) {
    margin-top: 20px;
  }
`;

export default function SinglePostPage({ data }) {
    const singleAdvice = data ? data.singleAdvice : null;
    if(!singleAdvice) return <p>Post didn't make it 😞</p>
    let relatedCategory = singleAdvice.comedian.categories.find(cat => {
      if (!cat.isGenre && cat.category !== 'Male' && cat.category !== 'Female') {
          return cat;
      }
  });

  if (!relatedCategory) {
    relatedCategory = singleAdvice.comedian.categories.find(cat => {
        if (cat.category !== 'Male' && cat.category !== 'Female') {
            return cat;
        }
    });
  }

  const categoryComedians = shuffleArray(relatedCategory.comedians.filter(com => com.id !== singleAdvice.comedian.id));
  const categoriesWithMoreAdvice = singleAdvice.categories.filter(cat => cat.adviceContents.length > 1);
  const moreAdviceLength = categoriesWithMoreAdvice.length;
  const endIndex = moreAdviceLength === 1 ? 20 : moreAdviceLength === 2 ? 10 : moreAdviceLength === 3 ? 7 : 5;
  return (
    <>
      <SEO title={`${singleAdvice.title}`} />
      <PageStyles>
        <StandardGridStyles>
          <Box>
            <AdviceGridStyles>
              <AdviceContentSingle key={generateId()} advice={singleAdvice} index={0} singleAdvicePage />
            </AdviceGridStyles>
            {categoriesWithMoreAdvice.map(category => {
              const relatedAdvice = category.adviceContents.filter(ad => ad.id !== singleAdvice.id);
              return (
                <RelatedPostsStyles key={category.id}>
                  <DesktopWrapper>
                    <GenericHeader header={`Related posts tagged '${category.category}'`} href={`/posts/category/${slugify(category.category)}`} />
                  </DesktopWrapper>
                  <MobileWrapper>
                    <GenericHeaderStyles>
                      <Text className="header">Related posts tagged <Link to={`/posts/category/${category.slug.current}`}>'{category.category}'</Link></Text>
                    </GenericHeaderStyles>
                  </MobileWrapper>
                  <AdviceGridStyles>
                    {shuffleArray(relatedAdvice).slice(0, endIndex).map((advice, index) => (
                        <AdviceContentSingle key={generateId()} advice={advice} index={index} />
                    ))}
                  </AdviceGridStyles>
                </RelatedPostsStyles>
              )
            })}
          </Box>
          <RightPanelStyles>
            <ComediansSearchBar noLabel />
            <Box mt="10px">
              <AdviceCategories />
            </Box>
            <Box mt="10px">
              <CreatePostInputLink />
            </Box>
            <Box mt="10px">
              <ComediansTrendingList />
            </Box>
          </RightPanelStyles>
        </StandardGridStyles>
      </PageStyles>
    </>
  );
}

// This needs to be dynamic based on the slug passed in via context in gatsby-node.js
export const query = graphql`
  query($slug: String!) {
    singleAdvice: sanityAdviceContent(slug: { current: { eq: $slug }}) {
      id
      title
      content
      slug {
          current
      }
      sourceLink
      sourceTitle
      comedian {
        id
        firstName
        lastName
        slug {
            current
        }
        avatar {
            asset {
                fluid {
                ...GatsbySanityImageFluid
                }
            }
        }
        categories {
          id
          category
          isGenre
          slug {
              current
          }
          comedians {
              id
              firstName
              lastName
              slug {
                  current
              }
              avatar {
                  asset {
                      fluid {
                      ...GatsbySanityImageFluid
                      }
                  }
              }
          }
        }
      }
      categories {
        id
        category
        slug {
            current
        }
        adviceContents {
          id
          title
          content
          slug {
              current
          }
          sourceLink
          sourceTitle
          comedian {
            id
            firstName
            lastName
            slug {
                current
            }
            avatar {
                asset {
                    fluid {
                    ...GatsbySanityImageFluid
                    }
                }
            }
          }
          categories {
            id
            category
            slug {
                current
            }
          }
        }
      }
    }
  }
`;