import React from 'react';
import styled from 'styled-components';
import { Text, Grid, Flex, Button } from '@chakra-ui/react';
import { Link } from 'gatsby';

const LandingPageMessageMobileStyles = styled.div`
  display: none;
  @media (max-width: 414px) {
    width: calc(100vw - 20px);
    display: block;
    padding: 15px 0 25px 0;
    margin-bottom: 25px;
    border-bottom: 1px dashed var(--facebook);
    text-align: center;
    .landing-grid {
    }
    h2 {
      font-weight: 700;
      font-size: 22px;
      letter-spacing: 0.5px;
      margin-top: -0.5rem;
      margin-bottom: 0.8rem;
    }
    .blue {
      color: var(--facebook);
    }
    .big {
      font-size: 14px;
      font-weight: 500;
    }
  }
  .buttons-flex {
    margin-top: 5px;
    /* justify-content: center; */
    flex-wrap: wrap;
    button {
      padding: 1.7rem 1.5rem;
      border-radius: 20px;
      font-size: 13px;
      margin: 0.3rem 0.3rem 0.3rem 0;
    }
  }
`;

export const LandingPageMessageMobile = () => (
  <LandingPageMessageMobileStyles>
    <Grid className="landing-grid">
      <h2 id="discover">
        Discover <span className="blue">comedians</span>
      </h2>
      <Text className="big">
        Yuks is a place where comedy fans can discover new comedians and learn
        more about their favorites.
      </Text>
      {/* <Text className="big" mt="20px">
        Go down the rabbit hole...
      </Text>
      <Flex className="buttons-flex">
        <Link to="/posts/category/creativity">
          <Button colorScheme="facebook">creative process</Button>
        </Link>
        <Link to="/posts/category/childhood">
          <Button colorScheme="facebook">childhood memories</Button>
        </Link>
        <Link to="/posts/category/fan-stories">
          <Button colorScheme="facebook">strangest fan encounters</Button>
        </Link>
        <Link to="/posts/category/advice-for-aspiring-comedians">
          <Button colorScheme="facebook">comedy advice</Button>
        </Link>
        <Link to="/posts/category/favorite-books">
          <Button colorScheme="facebook">favorite books</Button>
        </Link>
        <Link to="/posts/category/quirks">
          <Button colorScheme="facebook">quirks</Button>
        </Link>
        <Link to="/posts/category/general-life-advice">
          <Button colorScheme="facebook">life advice</Button>
        </Link>
      </Flex> */}
    </Grid>
  </LandingPageMessageMobileStyles>
);
