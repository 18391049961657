/* eslint-disable react/prop-types */
import React from 'react';
import { Text } from '@chakra-ui/react';
import { Link } from 'gatsby';
import styled from 'styled-components';

export const GenericHeaderStyles = styled.div`
  margin-bottom: 1rem;
  width: 100%;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 1rem;
  border-bottom: 1px solid #e2e8f0;
  p {
    text-transform: uppercase;
  }
  .header {
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.5px;
  }
  .more {
    padding-right: 0.3rem;
    justify-self: end;
    font-size: 11px;
    font-weight: 400;
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
  #for-comedian-name {
    @media (max-width: 414px) {
      display: none;
    }
  }
  .switch-text {
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.5px;
    color: #838383;
    &:hover {
      cursor: pointer;
      color: black;
    }
  }
  #selected {
    font-weight: 500;
    color: black;
  }
`;

export const GenericHeader = ({ header, href }) => (
  <GenericHeaderStyles>
    <Text className="header">{header}</Text>
    {href && (
      <Link to={href} className="more">
        <Text>More</Text>
      </Link>
    )}
  </GenericHeaderStyles>
);
