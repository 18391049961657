import React from 'react';
import { Text } from '@chakra-ui/react';
import styled from 'styled-components';
import { Link } from 'gatsby';

export const GridImagesSmallStyles = styled.div`
  padding: 0.4rem 0.2rem;
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
  position: absolute;
  bottom: 3px;
  left: 3px;
  width: calc(100% - 6px);
  z-index: 2;
  background: black;
  border-radius: 3px;
  @media (max-width: 414px) {
    display: none;
  }
  p {
    text-align: center;
    font-size: 10px;
    color: white;
  }
`;

export const ComedianImageHoveredPopup = ({ comedian }) => {
  const comedianName =
    comedian.lastName.length < 3
      ? `${comedian.firstName} ${comedian.lastName}`
      : `${comedian.firstName.slice(0, 1)}. ${comedian.lastName.slice(0, 11)}`;
  return (
    <GridImagesSmallStyles>
      <Link to={`/comedian/${comedian.slug.current}`}>
        <Text>{comedianName}</Text>
      </Link>
    </GridImagesSmallStyles>
  );
};
