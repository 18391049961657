/* eslint-disable array-callback-return */
/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-cycle */
import React from 'react';
import { graphql, Link } from 'gatsby';
import { Box, Text } from '@chakra-ui/react';
import styled from 'styled-components';
import SEO from '../components/SEO';
import { AdviceGrid } from '../components/AdviceGrid';
import { AdviceCategories } from '../components/AdviceCategories';
import { CreatePostInputLink } from '../components/CreatePostInputLink';
import { GenericHeader } from '../components/GenericHeader';
import { ComediansTrending } from '../components/ComediansTrending';
import { ComediansTrendingList } from '../components/ComediansTrendingList';
import { LandingPageMessageMobile } from '../components/LandingPageMessageMobile';
import { MobileWrapper } from '../templates/Post';
import { CreatePostLinkStyles } from '../templates/Comedian';
import { ComediansSearchBar } from '../components/ComediansSearchBar';

export const PageStyles = styled.div`
  margin-top: 40px;
  margin-bottom: 40px;
  @media (max-width: 640px) {
    margin-top: 0px;
    padding-top: 20px;
  }
  @media (max-width: 414px) {
    padding-top: 10px;
    margin-bottom: 0;
  }
`;

export const StandardGridStyles = styled.div`
  display: grid;
  grid-template-columns: 1fr 280px;
  grid-gap: 2rem;
  @media (max-width: 1040px) {
    grid-template-columns: 1fr 200px;
  }
  @media (max-width: 840px) {
    grid-template-columns: 1fr;
  }
`;

export const RightPanelStyles = styled.div`
  @media (max-width: 840px) {
    display: none;
  }
`;

export default function HomePage({ data }) {
  const {
    makingAShowAdvice,
    fanStoriesAdvice,
    generalLifeAdvice,
    foamCornerAdvice,
  } = data;
  const { comedians } = data;
  const mostPopularAllTimeComedians = [...comedians.nodes].sort((a, b) =>
    a.viewsAllTime > b.viewsAllTime
      ? -1
      : a.viewsAllTime < b.viewsAllTime
      ? 1
      : 0
  );
  const comediansTrendingThisWeek = [...comedians.nodes].sort((a, b) =>
    a.viewsThisWeek > b.viewsThisWeek
      ? -1
      : a.viewsThisWeek < b.viewsThisWeek
      ? 1
      : 0
  );

  return (
    <>
      <SEO title="Yuks" />
      <PageStyles>
        <StandardGridStyles>
          <Box>
            <MobileWrapper>
              <LandingPageMessageMobile />
            </MobileWrapper>
            <Box>
              <GenericHeader header="Trending this week" href="/comedians" />
              <ComediansTrending comedians={comediansTrendingThisWeek} />
            </Box>
            <Box mt="20px">
              <GenericHeader header="Most popular all-time" href="/comedians" />
              <ComediansTrending comedians={mostPopularAllTimeComedians} />
            </Box>
            <Box mt="20px">
              <GenericHeader
                header="Making a show"
                href="/posts/category/making-a-show"
              />
              <AdviceGrid allAdvice={makingAShowAdvice.nodes} standalonePage />
            </Box>
            <Box mt="20px">
              <GenericHeader
                header="Fan stories"
                href="/posts/category/fan-stories"
              />
              <AdviceGrid allAdvice={fanStoriesAdvice.nodes} standalonePage />
            </Box>
            <Box mt="20px">
              <GenericHeader
                header="General life advice"
                href="/posts/category/general-life-advice"
              />
              <AdviceGrid allAdvice={generalLifeAdvice.nodes} standalonePage />
            </Box>
            <Box mt="20px">
              <GenericHeader
                header="Harris' Foam Corner"
                href="/posts/category/foam-corner"
              />
              <AdviceGrid allAdvice={foamCornerAdvice.nodes} standalonePage />
            </Box>
            <CreatePostLinkStyles>
              <Link to="/posts" className="more">
                <Text>More posts</Text>
              </Link>
            </CreatePostLinkStyles>
          </Box>
          <RightPanelStyles>
            <ComediansSearchBar noLabel />
            <Box mt="10px">
              <AdviceCategories />
            </Box>
            <Box mt="10px">
              <CreatePostInputLink />
            </Box>
            <Box mt="10px">
              <ComediansTrendingList />
            </Box>
          </RightPanelStyles>
        </StandardGridStyles>
      </PageStyles>
    </>
  );
}

export const query = graphql`
  query {
    makingAShowAdvice: allSanityAdviceContent(
      filter: {
        _id: {
          in: [
            "b8e4ac6f-37a3-4ba4-a273-408005b39b99"
            "577145f7-75e7-4a84-b20e-6f06978d4827"
            "6cc545a5-b73b-4fb9-916b-66569735dd5f"
          ]
        }
      }
    ) {
      nodes {
        id
        title
        slug {
          current
        }
        content
        sourceLink
        sourceTitle
        comedian {
          id
          firstName
          lastName
          slug {
            current
          }
          avatar {
            asset {
              fluid {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
        categories {
          id
          slug {
            current
          }
          category
        }
        _createdAt
      }
    }
    fanStoriesAdvice: allSanityAdviceContent(
      filter: {
        _id: {
          in: [
            "cb2e0d35-a273-4eae-a977-d65ecd25b0c9"
            "362683ad-6dbb-4bd0-9ee6-720b3b340f9f"
            "fb2089d0-6484-4454-a4ef-faa65335ba4e"
          ]
        }
      }
    ) {
      nodes {
        id
        title
        slug {
          current
        }
        content
        sourceLink
        sourceTitle
        comedian {
          id
          firstName
          lastName
          slug {
            current
          }
          avatar {
            asset {
              fluid {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
        categories {
          id
          slug {
            current
          }
          category
        }
        _createdAt
      }
    }
    generalLifeAdvice: allSanityAdviceContent(
      filter: {
        _id: {
          in: [
            "0d70eab5-93d8-44d0-98be-c3e56b5c9f16"
            "7094960a-1229-4c0a-b503-9a36967b472b"
            "aa64818f-f7cd-43a5-b899-c2db552fbbf5"
            "f5a77ea8-bc5b-4bb9-98e8-54a1864f36e6"
          ]
        }
      }
    ) {
      nodes {
        id
        title
        slug {
          current
        }
        content
        sourceLink
        sourceTitle
        comedian {
          id
          firstName
          lastName
          slug {
            current
          }
          avatar {
            asset {
              fluid {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
        categories {
          id
          slug {
            current
          }
          category
        }
        _createdAt
      }
    }
    foamCornerAdvice: allSanityAdviceContent(
      filter: {
        _id: {
          in: [
            "b5b56c72-cbaa-4bc2-94de-e9ff1e9535cf"
            "f1521336-f17f-4740-b832-b0fe9b98b850"
            "1b64b756-b28f-4923-a6d2-d824876f6cf1"
            "03439d2d-74e9-4c48-8bf1-04d048914a52"
          ]
        }
      }
    ) {
      nodes {
        id
        title
        slug {
          current
        }
        content
        sourceLink
        sourceTitle
        comedian {
          id
          firstName
          lastName
          slug {
            current
          }
          avatar {
            asset {
              fluid {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
        categories {
          id
          slug {
            current
          }
          category
        }
        _createdAt
      }
    }
    comedians: allSanityComedian(sort: { fields: viewsAllTime, order: DESC }) {
      nodes {
        id
        firstName
        lastName
        slug {
          current
        }
        _createdAt
        avatar {
          asset {
            fluid {
              ...GatsbySanityImageFluid
            }
          }
        }
        viewsThisWeek
        viewsAllTime
        rankThisWeek
        rankAllTime
      }
    }
  }
`;
