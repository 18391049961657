/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-cycle */
/* eslint-disable react/self-closing-comp */
/* eslint-disable prettier/prettier */
/* eslint-disable arrow-body-style */
/* eslint-disable react/prop-types */
import { Box, Grid, Text, Flex, Button, Switch } from '@chakra-ui/react';
import React, { useState } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { GrEdit } from 'react-icons/gr';
import { slugify } from '../utils/slugify';
import { GenericHeader } from './GenericHeader';
import { ComedianFactSingle } from './ComedianFactSingle';

export const ComponentStyles = styled.div`
    .wrapper {
        width: 1000px;
        margin: 0 auto;
        overflow: hidden;
        display: grid;
        grid-template-columns: 300px 1fr;
        grid-gap: 3rem;
        @media(max-width: 1040px) {
            width: 800px;
            grid-template-columns: 1fr;
        }
        @media(max-width: 840px) {
            width: 600px;
        }
        @media(max-width: 640px) {
            width: 95vw;
        }
    }
    .ranking-buttons-grid {
        grid-template-columns: 1fr 1fr;
        grid-gap: 1rem;
        @media(max-width: 1040px) {
            grid-template-columns: 1fr 1fr 1fr 1fr;
        }
        @media(max-width: 414px) {
            grid-template-columns: 1fr 1fr;
        }
    }
    .ranking-inner-wrapper {
        padding: 1rem 0.5rem 0 0.5rem;
        grid-template-rows: 25px 1fr;
        justify-items: center;
        align-items: center;
        background: #f9f9f9;
        border: 1px solid #e2e8f0;
        border-radius: 3px;
        color: white;
        line-height: 0;
        width: 100%;
        height: 80px;
        &:hover {
            cursor: pointer;
            background: #F0F0F0;
        }
        .rank {
            font-size: 24px;
            font-weight: 500;
            text-align: center;
            align-self: start;
            color: #4056a1;
            margin-top: 1.2rem;
        }
        .sub-header {
            font-size: 12px;
            font-weight: 500;
            text-align: center;
            text-transform: uppercase;
            color: #838383;
            letter-spacing: -0.5px;
            line-height: 1;
        }
    }
    /* .category-buttons-grid {
        margin-top: -0.5rem;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 5px;
        @media (max-width: 1040px) {
            grid-template-columns: 1fr 1fr 1fr;
        }
        @media (max-width: 414px) {
            grid-template-columns: 1fr 1fr;
        }
    }
    .category-wrapper {
        padding: 5px 10px;
        border: 1px solid #b8c9da;
        background: #f9f9f9;
        border-radius: 3px;
        width: 100%;
        height: 45px;
        display: grid;
        grid-template-columns: 1fr;
        justify-items: center;
        align-items: center;
        &:hover {
            background: #F0F0F0;
        }
        .category {
            font-size: 12px;
            font-weight: 400;
            line-height: 1.2;
            text-align: center;
        }
    } */
    .about-section {
        position: relative;
        @media(max-width: 1040px) {
            grid-row: 1 / span 1;
        }
    }
    .suggestion-btn {
        position: absolute;
        right: 0px;
        top: 24px;
        font-size: 14px;
        height: 30px;
        width: 30px;
        padding: 0;
        border: 1px solid #CBD5E0;
    }
    .single-section-wrapper {
        margin-top: 2rem;
    }
    .comedian-facts-wrapper {
        margin-top: 10px;
        /* padding: 10px;
        border: 1px solid #b8c9da;
        background: #f9f9f9;
        border-radius: 3px; */
    }
`;

export const HeaderH2Styles = styled.h2`
    width: 100%;
    margin: 0.5rem auto;
    padding-left: 1rem;
    font-size: 22px;
    font-weight: 400;
    border-bottom: 3px solid #4056a1;
`;

export const ComedianRankingsAndAbout = ({ comedian }) => {
    const [showAllRankings, setShowAllRankings] = useState(false);

    const numberSections = comedian.facts.length;
    const categoriesLength = comedian.categories.length;

    let categoriesAndRanks = [];
    comedian.categories.sort((a, b) => a.category < b.category ? -1 : a.category > b.category ? 1 : 0).forEach(category => {
        const comedians = category.comedians.sort((a, b) => a.viewsAllTime > b.viewsAllTime ? -1 : a.viewsAllTime < b.viewsAllTime ? 1 : 0);
        const rank = comedians.findIndex(c => c.id === comedian.id);
        categoriesAndRanks.push({ category: category.category, categoryShortName: category.categoryShortName, rank: rank + 1 });
    });
    if (categoriesAndRanks.length >= (numberSections * 2) && !showAllRankings) {
        categoriesAndRanks = [...categoriesAndRanks].slice(0,(numberSections * 2));
    } else {
        categoriesAndRanks = [...categoriesAndRanks];
    }

    return (
        <ComponentStyles>
            <Grid className="wrapper">
               <Box>
                <GenericHeader header="Categories" />
                <Grid className="ranking-buttons-grid">
                    {categoriesAndRanks.map(item => {
                        const href = `/comedians/category/${slugify(item.category)}`;
                        return (
                            <Link to={href} key={item.id}>
                                <Box className="ranking-outer-wrapper">
                                    <Grid className="ranking-inner-wrapper">
                                        <Text className="sub-header">{item.categoryShortName}</Text>
                                        <Text className="rank">#{item.rank}</Text>
                                    </Grid>
                                </Box>
                            </Link>
                        )
                    })}
                </Grid>
                {categoriesLength > (numberSections * 2) && (
                    <Flex mt="5px">
                        <Text fontSize="12px" color="#606060">Show {showAllRankings ? 'less' : 'more'} categories</Text>
                        <Switch size="sm" ml="6px" mt="-2.5px" onChange={() => setShowAllRankings(!showAllRankings)} />
                    </Flex>
                )}
               </Box>
               <Box className="about-section">
                    <Link
                        to={`/add-fact?c=${slugify(
                            `${comedian.firstName} ${comedian.lastName}`
                        )}`}
                    >
                        <Button className="suggestion-btn">
                            <GrEdit />
                        </Button>
                    </Link>
                    <GenericHeader header={`A little about ${comedian.firstName}`} />
                    <Box className="comedian-facts-wrapper">
                        {comedian.facts.map((fact, index) => (
                            <ComedianFactSingle fact={fact} index={index} key={fact.id} />
                        ))}
                    </Box>
                </Box>
            </Grid>
        </ComponentStyles>
    )
}