/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-cycle */
/* eslint-disable prettier/prettier */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Text, Flex, Button, Box, Grid } from '@chakra-ui/react';
import { FiExternalLink } from 'react-icons/fi';
import { FaRegComment } from 'react-icons/fa';
import { DiscussionEmbed } from 'disqus-react';
import Img from 'gatsby-image';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { generateId } from '../utils/generateId';
import { CommentCountDisqus } from './CommentCountDisqus';

export const AdviceContentSingleStyles = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 1.5rem;
  padding: 1.5rem;
  border: 1px solid #b8c9da;
  background: #f9f9f9;
  border-radius: 5px;
  @media (max-width: 640px) {
    grid-template-columns: 1fr;
    grid-gap: 1rem;
  }
  .advice-header-flex-desktop {
    @media (max-width: 414px) {
      display: none;
    }
  }
  .comedian-avatar {
      &:hover {
          cursor: pointer;
      }
  }
  .comedian-avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
  }
  .comedian-topic-wrapper {
    margin-bottom: 0.5rem;
    line-height: 1.25;
  }
  .comedian-name {
    margin: 0;
    margin-top: 3px;
    font-size: 1.3rem;
    font-weight: 500;
    letter-spacing: 0.2px;
    color: var(--facebook);
  }
  .advice-header-grid-mobile {
    display: none;
    @media (max-width: 414px) {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 0.5rem;
      .avatar-name-flex {
        align-items: center;
        flex-wrap: wrap;
      }
      .comedian-avatar {
        width: 20px;
        height: 20px;
      }
      .comedian-name {
        margin: 0;
        font-size: 1.3rem;
        margin-left: 1rem;
      }
    }
  }
  .name-span {
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
  .topic {
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 1.25;
    display: inline-block;
    letter-spacing: 0;
    padding-right: 10px;
    &:hover {
      color: var(--facebook);
      cursor: pointer;
    }
  }
  .advice {
    margin: 1.5rem 0;
    font-size: 14px;
    font-weight: 400;
    white-space: pre-wrap;
    padding-right: 3rem;
    line-height: 1.5;
    overflow-wrap: break-word;
    @media (max-width: 640px) {
      margin-top: 0.5rem;
      padding-right: 0.2rem;
    }
    @media (max-width: 414px) {
      margin-top: 0;
      padding-right: 0.2rem;
    }
  }
  .source-wrapper-flex {
    margin-top: 0.5rem;
    padding: 0.2rem 1rem 0.2rem 0;
    p,
    a {
      font-size: 13px;
      line-height: 1.5;
    }
    a {
      color: #4056a1;
      font-weight: 500;
      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }
  .like-grid {
    margin-top: 0.5rem;
    grid-template-columns: auto 1fr;
    padding: 0 1rem 0 0;
  }
  .categories-list-flex {
    align-items: center;
    flex-wrap: wrap;
    @media (max-width: 640px) {
      display: grid;
      grid-template-columns: auto 1fr;
      align-items: start;
    }
  }
  .tags {
    font-size: 13px;
    margin-right: 5px;
    margin-bottom: -5px;
    @media (max-width: 640px) {
      padding-top: 7.5px;
    }
  }
  .category-btn {
    &:hover {
      background: #A0AEC0;
    }
  }
  .wide {
    @media (max-width: 640px) {
      display: none;
    }
  }
  .narrow {
    display: none;
    @media (max-width: 640px) {
      display: block;
      margin-left: 1.25rem;
    }
  }
  .external-link {
    position: absolute;
    right: 10px;
    top: 16.5px;
    color: #b8c9da;
    @media (max-width: 414px) {
      top: auto;
      bottom: 19px;
    }
  }
  .name-number-comments-flex {
    align-items: center;
  }
  .comments-wrapper {
    align-items: center;
    margin-left: 8px;
    margin-bottom: -3px;
    @media (max-width: 414px) {
      margin-bottom: 0;
      margin-top: -1px;
    }
  }
  .comment-icon {
    font-size: 12px;
    color: #878a8d;
    margin-right: 3px;
  }
`;

export const DisqusWrapperStyles = styled.div`
  margin-top: 10px;
  padding: 0 1rem;
  grid-column: 1 / span 2;
  @media (max-width: 640px) {
    grid-column: 1 / span 1;
    padding: 0 5px;
  }
`;

export const AdviceContentSingle = ({ advice, standalonePage, index, borderTop, singleAdvicePage }) => {
  const {comedian} = advice;
  const [showFullAdvice, setShowFullAdvice] = useState(false);

  const isLongAdvice = advice.content.length > 700;
  let adviceContentSliced = isLongAdvice ? `${advice.content.slice(0,700)}...` : advice.content;
  if (showFullAdvice) {
    adviceContentSliced = advice.content;
  }

  const url = `https://www.yuks.co/post/${advice.slug.current}`;
  const identifier = advice.id;
  const {title} = advice;

  return (
    <AdviceContentSingleStyles key={generateId()} style={{ paddingBottom: singleAdvicePage ? '5px' : '15px'}}>
      {!singleAdvicePage && (
        <a className="external-link" href={advice.sourceLink} target="blank">
          <FiExternalLink />
        </a>
      )}
      <Grid className="advice-header-grid-mobile">
        {standalonePage && (
          <>
            <Flex className="avatar-name-flex">
              <Box className="avatar-wrapper" pointerEvents="none">
                <Img className="comedian-avatar" fluid={advice.comedian.avatar.asset.fluid} alt={`${advice.comedian.firstName} ${advice.comedian.lastName}`} />
              </Box>
              <Flex className="name-number-comments-flex">
                <Text className="comedian-name" pointerEvents="none"><span className="name-span">{advice.comedian.firstName} {advice.comedian.lastName}</span></Text>
                <Link to={`/post/${advice.slug.current}`} style={{ pointerEvents: singleAdvicePage ? 'none' : 'auto' }}>
                  <Flex className="comments-wrapper">
                    <FaRegComment className="comment-icon" />
                    <CommentCountDisqus url={url} identifier={identifier} title={title} />
                  </Flex>
                </Link>
              </Flex>
            </Flex>
            <Link to={`/post/${advice.slug.current}`} style={{ pointerEvents: singleAdvicePage ? 'none' : 'auto' }}>
              <Text className="topic">{advice.title}</Text>
            </Link>
          </>
        )}
        {!standalonePage && (
          <>
            <Flex className="avatar-name-flex">
              <Link to={`/comedian/${comedian.slug.current}`}>
                <Box className="avatar-wrapper">
                  <Img className="comedian-avatar" fluid={advice.comedian.avatar.asset.fluid} alt={`${advice.comedian.firstName} ${advice.comedian.lastName}`} />
                </Box>
              </Link>
              <Flex className="name-number-comments-flex">
                <Text className="comedian-name"><Link to={`/comedian/${comedian.slug.current}`}><span className="name-span">{advice.comedian.firstName} {advice.comedian.lastName}</span></Link></Text>
                <Link to={`/post/${advice.slug.current}`} style={{ pointerEvents: singleAdvicePage ? 'none' : 'auto' }}>
                  <Flex className="comments-wrapper">
                    <FaRegComment className="comment-icon" />
                    <CommentCountDisqus url={url} identifier={identifier} title={title} />
                  </Flex>
                </Link>
              </Flex>
            </Flex>
            <Link to={`/post/${advice.slug.current}`} style={{ pointerEvents: singleAdvicePage ? 'none' : 'auto' }}>
              <Text className="topic">{advice.title}</Text>
            </Link>
          </>
        )}
      </Grid>
      <Flex className="advice-header-flex-desktop">
        {standalonePage && (
          <Box className="avatar-wrapper" pointerEvents="none">
            <Img className="comedian-avatar" fluid={advice.comedian.avatar.asset.fluid} alt={`${advice.comedian.firstName} ${advice.comedian.lastName}`} />
          </Box>
        )}
        {!standalonePage && (
          <Link to={`/comedian/${comedian.slug.current}`}>
            <Box className="avatar-wrapper">
              <Img className="comedian-avatar" fluid={advice.comedian.avatar.asset.fluid} alt={`${advice.comedian.firstName} ${advice.comedian.lastName}`} />
            </Box>
          </Link>
        )}
        <Box className="comedian-topic-wrapper narrow">
          <Link to={`/post/${advice.slug.current}`} style={{ pointerEvents: singleAdvicePage ? 'none' : 'auto' }}>
            <Text className="topic">{advice.title}</Text>
          </Link>
          {!standalonePage && (
            <Flex className="name-number-comments-flex">
              <Text className="comedian-name"><Link to={`/comedian/${comedian.slug.current}`}><span className="name-span">{advice.comedian.firstName} {advice.comedian.lastName}</span></Link></Text>
              <Link to={`/post/${advice.slug.current}`} style={{ pointerEvents: singleAdvicePage ? 'none' : 'auto' }}>
                <Flex className="comments-wrapper">
                  <FaRegComment className="comment-icon" />
                  <CommentCountDisqus url={url} identifier={identifier} title={title} />
                </Flex>
              </Link>
            </Flex>
          )}
          {standalonePage && (
            <Flex className="name-number-comments-flex">
              <Text className="comedian-name" pointerEvents="none"><span className="name-span">{advice.comedian.firstName} {advice.comedian.lastName}</span></Text>
              <Link to={`/post/${advice.slug.current}`} style={{ pointerEvents: singleAdvicePage ? 'none' : 'auto' }}>
                <Flex className="comments-wrapper">
                  <FaRegComment className="comment-icon" />
                  <CommentCountDisqus url={url} identifier={identifier} title={title} />
                </Flex>
              </Link>
            </Flex>
          )}
        </Box>
      </Flex>
      <Box>
        <Box className="comedian-topic-wrapper wide">
          <Link to={`/post/${advice.slug.current}`} style={{ pointerEvents: singleAdvicePage ? 'none' : 'auto' }}>
            <Text className="topic">{advice.title}</Text>
          </Link>
          {!standalonePage && (
            <Flex className="name-number-comments-flex">
              <Text className="comedian-name"><Link to={`/comedian/${comedian.slug.current}`}><span className="name-span">{advice.comedian.firstName} {advice.comedian.lastName}</span></Link></Text>
              <Link to={`/post/${advice.slug.current}`} style={{ pointerEvents: singleAdvicePage ? 'none' : 'auto' }}>
                <Flex className="comments-wrapper">
                  <FaRegComment className="comment-icon" />
                  <CommentCountDisqus url={url} identifier={identifier} title={title} />
                </Flex>
              </Link>
            </Flex>
          )}
          {standalonePage && (
            <Flex className="name-number-comments-flex">
              <Text className="comedian-name" pointerEvents="none"><span className="name-span">{advice.comedian.firstName} {advice.comedian.lastName}</span></Text>
              <Link to={`/post/${advice.slug.current}`} style={{ pointerEvents: singleAdvicePage ? 'none' : 'auto' }}>
                <Flex className="comments-wrapper">
                  <FaRegComment className="comment-icon" />
                  <CommentCountDisqus url={url} identifier={identifier} title={title} />
                </Flex>
              </Link>
            </Flex>
          )}
        </Box>
        <Box className="advice">
          {adviceContentSliced}
          {isLongAdvice && (
            <Button ml="3px" fontSize="12px" padding="0" pb="3px" color="#4056a1" colorScheme="none" onClick={() => setShowFullAdvice(!showFullAdvice)}>[Read {showFullAdvice ? 'less' : 'more'}]</Button>
          )}
        </Box>
        {advice.sourceTitle && (
          <>
            {/* <Flex className="source-wrapper-flex">
              <Text mr="5px">Source: </Text>
              <a href={advice.sourceLink} target="blank">
                {advice.sourceTitle}
              </a>
            </Flex> */}
            <Flex className="categories-list-flex">
                <Text className="tags">Tags: </Text>
                <Flex flexWrap="wrap" paddingRight="3px">
                  {advice.categories.map((category) => (
                      <Link key={category.id} to={`/posts/category/${category.slug.current}`}>
                        <Button mt="5px" className="category-btn" bg="#E2E8F0" mr="5px" fontSize="12px" fontWeight="500">{category.category}</Button>
                      </Link>
                  ))}
                </Flex>
            </Flex>
          </>
        )}
      </Box>
      {singleAdvicePage && (
        <DisqusWrapperStyles>
          <DiscussionEmbed
              shortname='yuks-1'
              config={
                  {
                      url,
                      identifier,
                      title,	
                  }
              }
          />
        </DisqusWrapperStyles>
      )}
    </AdviceContentSingleStyles>
  );
};
