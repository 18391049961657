/* eslint-disable import/no-cycle */
/* eslint-disable prettier/prettier */
/* eslint-disable react/prop-types */
import React from 'react';
import styled from 'styled-components';
import { ComedianImageSmall } from './ComedianImageSmall';
import { ComediansGridStyles } from './ComediansGrid';

const ComediansSingleRowStyles = styled.div`
    max-height: 97px;
    overflow: hidden;
    @media (max-width: 414px) {
        max-height: 192px;
    }
`;

export const ComediansSingleRow = ({ comedians }) => (
    <ComediansSingleRowStyles>
        <ComediansGridStyles>
            {comedians.map((comedian, index) => (
                <ComedianImageSmall comedian={comedian} key={comedian.id} index={index} />
            ))}
        </ComediansGridStyles>
    </ComediansSingleRowStyles>
  );
