/* eslint-disable import/no-cycle */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-children-prop */
import React from 'react';
import { Select } from '@chakra-ui/react';
import styled from 'styled-components';
import { graphql, useStaticQuery } from 'gatsby';
import { useNavigate } from '@reach/router';

export const BrowseByStyles = styled.div`
  width: 95px;
  overflow: hidden;
  display: grid;
  grid-template-columns: 1fr;
  align-items: end;
  justify-content: end;
  justify-items: end;
  .label {
    font-size: 12px;
    font-weight: 400;
    text-transform: uppercase;
  }
  .browse-by-select {
    height: 20px;
    font-size: 11px;
    font-weight: 400;
    padding: 0 1.8rem 0 1rem;
    background: #f9f9f9;
  }
`;

export const AdviceBrowseBy = () => {
  const navigate = useNavigate();

  const { categories } = useStaticQuery(graphql`
    query {
      categories: allSanityAdviceCategory(
        sort: { fields: category, order: ASC }
      ) {
        nodes {
          id
          category
          slug {
            current
          }
          adviceContents {
            id
            title
            content
            comedian {
              id
              firstName
              lastName
            }
          }
        }
      }
    }
  `);
  const handleCategoryChange = (slug) => {
    navigate(`/posts/category/${slug}`);
  };
  return (
    <>
      <BrowseByStyles>
        <Select
          className="browse-by-select"
          size="lg"
          onChange={(e) => handleCategoryChange(e.target.value)}
        >
          <option value="">CATEGORY</option>
          {categories.nodes.map((category) => (
            <option key={category.id} value={category.slug.current}>
              {category.category}
            </option>
          ))}
        </Select>
      </BrowseByStyles>
    </>
  );
};
