/* eslint-disable import/no-cycle */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-children-prop */
import React from 'react';
import { Text, Select, Box } from '@chakra-ui/react';
import styled from 'styled-components';
import { graphql, useStaticQuery } from 'gatsby';
import { useNavigate } from '@reach/router';
import { AdviceSearchBar } from './AdviceSearchBar';

export const SpecialsHeaderStyles = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: grid;
  grid-template-columns: 80px 80px 105px auto 1fr;
  grid-gap: 1rem;
  align-items: center;
  border-bottom: 1px solid #e2e8f0;
  padding-bottom: 1rem;
  @media (max-width: 840px) {
    grid-template-columns: 80px 80px 105px 100px;
    justify-content: center;
  }
  @media (max-width: 640px) {
    grid-template-columns: 80px 80px 105px 93px;
    grid-gap: 0.5rem;
  }
  @media (max-width: 414px) {
    grid-template-columns: 80px 1fr 1.2fr;
  }
  &.advice,
  &.comedians {
    /* grid-template-columns: 80px 110px 105px 1fr; */
    grid-template-columns: 80px 110px 1fr;
    /* @media (max-width: 840px) {
      grid-template-columns: 80px 110px 1fr;
    } */
    @media (max-width: 640px) {
      /* grid-template-columns: auto 190px; */
      grid-template-columns: 80px 110px 1fr;
    }
    @media (max-width: 414px) {
      grid-template-columns: 120px 1fr;
    }
  }
  &.comedians {
    @media (max-width: 414px) {
      grid-template-columns: 134px 1fr;
    }
  }
  &#no-underline {
    border-bottom: none;
  }
  .label {
    font-size: 13px;
    font-weight: 400;
    text-transform: uppercase;
    margin-bottom: -3px;
  }
  .browse-by-select {
    height: 30px;
    font-size: 13px;
    padding: 0 1.8rem 0 1rem;
    background: #f9f9f9;
    @media (max-width: 414px) {
      width: 100%;
    }
  }
  .search-wrapper {
    justify-self: end;
    @media (max-width: 414px) {
      justify-self: center;
      grid-row: 2 / span 1;
      grid-column: 1 / -1;
    }
  }
`;

export const AdviceSortByHeader = ({ noUnderline }) => {
  const navigate = useNavigate();

  const { categories } = useStaticQuery(graphql`
    query {
      categories: allSanityAdviceCategory(
        sort: { fields: category, order: ASC }
      ) {
        nodes {
          id
          category
          slug {
            current
          }
          adviceContents {
            id
            title
            content
            comedian {
              id
              firstName
              lastName
            }
          }
        }
      }
    }
  `);

  // TODO: pagination and popular
  // const handleChange = (page, str) => {
  //   navigate(`/advice/${str}/${page}`);
  // };
  // const handleChange = (slug, str) => {
  //   navigate(`/advice/${str}/${slug}`);
  // };

  const handleCategoryChange = (slug) => {
    navigate(`/posts/category/${slug}`);
  };
  return (
    <>
      <SpecialsHeaderStyles
        className="advice"
        id={noUnderline ? 'no-underline' : ''}
      >
        <Text className="label">Browse by</Text>
        <Select
          className="browse-by-select"
          size="lg"
          onChange={(e) => handleCategoryChange(e.target.value)}
        >
          <option value="">CATEGORY</option>
          {categories.nodes.map((category) => (
            <option key={category.id} value={category.slug.current}>
              {category.category}
            </option>
          ))}
        </Select>
        <Box className="search-wrapper advice-search">
          <AdviceSearchBar />
        </Box>
      </SpecialsHeaderStyles>
    </>
  );
};
