import React, { useState } from 'react';
import { Box, Text, Button } from '@chakra-ui/react';
import styled from 'styled-components';

const ComedianFactSingleStyles = styled.div`
  margin-top: 2rem;
  .fact-header {
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 1;
    letter-spacing: 0;
  }
  .fact-content-wrapper {
    margin-top: 0.5rem;
    font-size: 14px;
    font-weight: 400;
    white-space: pre-wrap;
    padding-right: 1rem;
    line-height: 1.5;
    overflow-wrap: break-word;
  }
  &#first {
    margin-top: 0;
  }
`;

const sliceFactSectionContent = (content, showFull) => {
  const isLong = content?.length > 250 || false;
  let contentSliced = isLong ? `${content.slice(0, 250)}...` : content;
  if (showFull) {
    contentSliced = content;
  }
  return contentSliced;
};

export const ComedianFactSingle = ({ fact, index }) => {
  const [showFullFact, setShowFullFact] = useState(false);
  const isLongFact = fact.fact.length > 250 || false;
  const factSliced = sliceFactSectionContent(fact.fact, showFullFact);
  return (
    <ComedianFactSingleStyles key={fact.id} id={index === 0 ? 'first' : ''}>
      <Text className="fact-header">{fact.title}</Text>
      <Box className="fact-content-wrapper">
        {factSliced}
        {isLongFact && (
          <Button
            ml="3px"
            fontSize="12px"
            padding="0"
            pb="3px"
            color="#4056a1"
            colorScheme="none"
            onClick={() => setShowFullFact(!showFullFact)}
          >
            [Read {showFullFact ? 'less' : 'more'}]
          </Button>
        )}
      </Box>
    </ComedianFactSingleStyles>
  );
};
