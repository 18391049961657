import React from 'react';
import { CommentCount } from 'disqus-react';
import styled from 'styled-components';

const CommentCountStyles = styled.div`
  font-size: 12px;
  color: #878a8d;
`;

export const CommentCountDisqus = ({ url, identifier, title }) => (
  <CommentCountStyles>
    <CommentCount
      shortname="yuks-1"
      config={{
        url,
        identifier,
        title,
      }}
    >
      0
    </CommentCount>
  </CommentCountStyles>
);
