/* eslint-disable no-plusplus */
export const getAgeAtDeath = (birthStr, deathStr) => {
  const birthDate = new Date(birthStr);
  const deathDate = new Date(deathStr);
  let age = deathDate.getFullYear() - birthDate.getFullYear();
  const m = deathDate.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && deathDate.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
};
