/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable array-callback-return */
/* eslint-disable react/prop-types */
/* eslint-disable import/no-cycle */
/* eslint-disable no-return-assign */
/* eslint-disable no-param-reassign */
/* eslint-disable no-else-return */
/* eslint-disable prettier/prettier */

import React, { useState, useEffect } from 'react';
import { graphql, Link } from 'gatsby';
import styled from 'styled-components';
import { useBottomScrollListener } from 'react-bottom-scroll-listener';
import { Box, Text } from '@chakra-ui/react';
import SEO from '../components/SEO';
import { AdviceGridStyles } from '../components/AdviceGrid';
import { PageStyles, RightPanelStyles, StandardGridStyles } from '../pages';
import { ComedianDetails } from '../components/ComedianDetails';
import { ComediansSingleRow } from '../components/ComediansSingleRow';
import { GenericHeader, GenericHeaderStyles } from '../components/GenericHeader';
import { shuffleArray } from '../utils/shuffleArray';
import { AdviceContentSingle } from '../components/AdviceContentSingle';
import { slugify } from '../utils/slugify';
import { ComedianRankingsAndAbout } from '../components/ComedianRankingsAndAbout';
import { AdviceCategories } from '../components/AdviceCategories';
import { CreatePostInputLink } from '../components/CreatePostInputLink';
import { ComediansTrendingList } from '../components/ComediansTrendingList';
import { ComediansSearchBar } from '../components/ComediansSearchBar';

export const CreatePostLinkStyles = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    justify-items: end;
    padding-right: 0.3rem;
    .more {
        margin-top: 3px;
        font-size: 11px;
        &:hover {
            cursor: pointer;
            text-decoration: underline;
        }
    }
    p {
        text-transform: uppercase;
        font-weight: 500;
    }
`;

export default function SingleComedianPage({ data }) {
    const advicePerPage = parseInt(process.env.GATSBY_ADVICE_PER_PAGE);
    const [categoryComedians, setCategoryComedians] = useState([]);
    const [endIndex, setEndIndex] = useState(advicePerPage);
    const callback = () => {
        setEndIndex(endIndex + advicePerPage);
    }
    useBottomScrollListener(callback, {
        offset: 500,
    });

    const comedian = data ? data.comedian : null;
    if(!comedian) return <p>Comedian didn't make it 😞</p>
    const totalNumberAdvice = comedian.adviceContents.length;
    const comedianAdvice = comedian.adviceContents.slice(0, endIndex);
    let category = comedian.categories.find(cat => {
        if (!cat.isGenre && cat.category !== 'Male' && cat.category !== 'Female' && !cat.category.toLowerCase().includes('born')) {
            return cat;
        }
    });
    if (!category) {
        category = comedian.categories.find(cat => {
            if (cat.category !== 'Male' && cat.category !== 'Female') {
                return cat;
            }
        });
    }

    useEffect(() => {
        const uniqueComedians = [];
        category.comedians.forEach((c) => {
            const uniqueIds = uniqueComedians.map(com => com.id);
            if (!uniqueIds.includes(c.id)) {
                uniqueComedians.push(c);
            }
        });
        setCategoryComedians(shuffleArray(uniqueComedians.filter(com => com.id !== comedian.id)));
    }, []);
    const firstNamePlural = comedian.firstName.charAt(comedian.firstName.length - 1) === 's' ? `${comedian.firstName}'` : `${comedian.firstName}'s`;
    return (
    <>
      <SEO title={`${comedian.firstName} ${comedian.lastName} | Yuks`} />
      <PageStyles>
        <ComedianDetails comedian={comedian} popularityRanking={5} trendingRanking={5} />
        <Box mt="35px">
            <ComedianRankingsAndAbout comedian={comedian} />
        </Box>
        <Box mt="35px">
            <GenericHeader header="Related comedians" href="/comedians" />
            <ComediansSingleRow comedians={categoryComedians.slice(0,10)} />
        </Box>
        <Box margin="30px 0">
            <StandardGridStyles>
                <Box>
                    <GenericHeaderStyles>
                        <Text className="header">{`${firstNamePlural} posts (${totalNumberAdvice})`}</Text>
                        <Link to={`/create-post?c=${slugify(`${comedian.firstName} ${comedian.lastName}`)}`} className="more">
                            <Text>Create post<span id="for-comedian-name"> for {comedian.firstName}</span></Text>
                        </Link>
                    </GenericHeaderStyles>
                    <AdviceGridStyles>
                        {comedianAdvice.map((advice, index) => (
                                <AdviceContentSingle key={advice.id} advice={advice} index={index} standalonePage />
                            ))}
                    </AdviceGridStyles>
                    <CreatePostLinkStyles>
                        <Link to={`/create-post?c=${slugify(`${comedian.firstName} ${comedian.lastName}`)}`} className="more">
                            <Text>Create post for {comedian.firstName}</Text>
                        </Link>
                    </CreatePostLinkStyles>
                </Box>
                <RightPanelStyles>
                    <ComediansSearchBar noLabel />
                    <Box mt="10px">
                        <AdviceCategories />
                    </Box>
                    <Box mt="10px">
                        <CreatePostInputLink />
                    </Box>
                    <Box mt="10px">
                        <ComediansTrendingList />
                    </Box>
                </RightPanelStyles>
            </StandardGridStyles>
        </Box>
      </PageStyles>
    </>
  );
}

// This needs to be dynamic based on the slug passed in via context in gatsby-node.js
export const query = graphql`
  query($slug: String!) {
    comedian: sanityComedian(slug: { current: { eq: $slug }}) {
        id
        firstName
        lastName
        slug {
            current
        }
        avatar {
            asset {
                fluid {
                ...GatsbySanityImageFluid
                }
            }
        }
        birthDay
        birthMonth
        birthYear
        deathDay
        deathMonth
        deathYear
        birthCity
        birthState
        birthStateAbbreviation
        birthCountry
        featuredLine
        viewsThisWeek
        viewsAllTime
        rankThisWeek
        rankAllTime
        categories {
            id
            category
            categoryShortName
            isGenre
            slug {
                current
            }
            comedians {
                id
                firstName
                lastName
                slug {
                    current
                }
                avatar {
                    asset {
                        fluid {
                        ...GatsbySanityImageFluid
                        }
                    }
                }
                viewsThisWeek
                viewsAllTime
            }
        }
        primaryCategories {
            id
            category
            isGenre
            slug {
                current
            }
        }
        adviceContents {
            id
            title
            content
            slug {
                current
            }
            sourceLink
            sourceTitle
            comedian {
                id
                firstName
                lastName
                slug {
                    current
                }
                avatar {
                    asset {
                        fluid {
                        ...GatsbySanityImageFluid
                        }
                    }
                }
            }
            categories {
                id
                category
                slug {
                    current
                }
            }
        }
        facts {
            id
            title
            fact
            slug {
                current
            }
            sourceLink
            sourceTitle
            # categories {
            #     id
            #     category
            #     slug {
            #         current
            #     }
            # }
        }
    }
  }
`;