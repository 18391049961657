import React from 'react';
import { Link } from 'gatsby';
import { InputGroup, Input } from '@chakra-ui/react';
import { SpecialsSearchBarStyles } from './AdviceSearchBar';

export const CreatePostInputLink = () => (
  <SpecialsSearchBarStyles>
    <InputGroup className="search-input-wrapper" id="no-label">
      <Link to="/create-post" className="create-post-link">
        <Input
          focusBorderColor="none"
          className="search-input"
          placeholder="Create a post"
          autoComplete="off"
          type="text"
          id="search"
          name="search"
        />
      </Link>
    </InputGroup>
  </SpecialsSearchBarStyles>
);
