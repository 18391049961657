/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-cycle */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-children-prop */
import React, { useState } from 'react';
import { Text } from '@chakra-ui/react';
import styled from 'styled-components';
import { graphql, useStaticQuery, Link } from 'gatsby';

export const AdviceCategoriesStyles = styled.div`
  background: #f9f9f9;
  border: 1px solid #e2e8f0;
  border-radius: 5px;
  h2 {
    padding: 10px;
    border-bottom: 1px solid #e2e8f0;
    font-weight: 700;
  }
  p {
    padding: 3px 10px;
    font-size: 12px;
    display: block;
    letter-spacing: 0;
    &#first {
      padding-top: 6px;
    }
    &#last {
      padding-bottom: 6px;
    }
    &:hover {
      background: #e2e8f0;
    }
  }
  .show-button {
    padding: 6px 10px 3px 10px;
    border-top: 1px solid #e2e8f0;
    font-weight: 600;
    &:hover {
      cursor: pointer;
    }
  }
`;

export const AdviceCategories = () => {
  const [showMore, setShowMore] = useState(false);
  const { categories } = useStaticQuery(graphql`
    query {
      categories: allSanityAdviceCategory(
        sort: { fields: category, order: ASC }
      ) {
        nodes {
          id
          category
          slug {
            current
          }
          adviceContents {
            id
            title
            content
            comedian {
              id
              firstName
              lastName
            }
          }
        }
      }
    }
  `);

  const sortByAdviceLength = (categoryArr) => {
    const arrSorted = [...categoryArr].sort((a, b) => {
      const aAdvice = a.adviceContents.length;
      const bAdvice = b.adviceContents.length;
      if (aAdvice > bAdvice) {
        return -1;
      }
      if (aAdvice < bAdvice) {
        return 1;
      }
      return 0;
    });
    return arrSorted;
  };

  let categoriesSorted = sortByAdviceLength([...categories.nodes]).filter(
    (category) => category.category !== 'Phone corner'
  );
  if (!showMore) {
    categoriesSorted = [...categoriesSorted].slice(0, 30);
  }

  return (
    <AdviceCategoriesStyles>
      <h2>Popular categories</h2>
      {categoriesSorted.map((category, index) => (
        <Link to={`/posts/category/${category.slug.current}`}>
          <Text
            id={
              index === 0
                ? 'first'
                : (!showMore && index + 1 === 30) ||
                  (showMore && index + 1 === categoriesSorted.length)
                ? 'last'
                : ''
            }
          >
            {category.category}
          </Text>
        </Link>
      ))}
      <Text className="show-button" onClick={() => setShowMore(!showMore)}>
        {showMore ? 'Show less' : 'Show all'}
      </Text>
    </AdviceCategoriesStyles>
  );
};
